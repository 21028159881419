import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction004 from './Introduction004'
import Introduction005 from './Introduction005'
import Introduction006 from './Introduction006'
import Introduction007 from './Introduction007'
import Introduction008 from './Introduction008'
import Introduction009 from './Introduction009'
import Introduction010 from './Introduction010'
import Introduction011 from './Introduction011'
import Introduction012 from './Introduction012'
import useRouteParam from '../../hooks/useRouteParam'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;

  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }

  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;

    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;

      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }

    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: fit-content;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }

    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;

      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }

      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;

        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }

      &.txt-color-red {
        color: #ff7070;
      }
    }

    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;

      > div {
        width: 50px;
      }

      .link-p {
        width: calc(100% - 50px);

        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;

          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }

    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }

    //  表用のCSS
    table {
      width: 100%;
      margin-bottom: 15px;


      td {
        padding: 5px;
        border: solid 1px rgba(200, 200, 200, 1);
        vertical-align: top;
      }
    }
  }

}

${BreakPoints.large} {
}
`
type Props = {
  /** 広告の番号 */
  no: number
}

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0089 = (props: Props): JSX.Element => {
  const routeParam = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0070/'
  )
  const routeParam2 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0068/'
  )
  const routeParam3 = useRouteParam('https://www.kintaicloud.jp/')
  const routeParam4 = useRouteParam(
    'https://www.kintaicloud.jp/column/details/C0067/'
  )

  return (
    <Wrapper>
      <ColumnH1 label="中小企業向け勤怠管理システムの選び方とは？おすすめシステムも紹介" />
      {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
      <p className="p-txt-date">2024.02.16</p>
      <section>
        {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
        <img
          src="/images/column/details/c0089.jpg"
          alt="キンクラコラム画像89"
        />
        <p>
          Excel(エクセル)から勤怠管理システムへの移行を検討している方もいるでしょう。システムを導入することで、勤怠管理が効率化されるため、集計作業を短時間で終わらせることができるといったメリットがあります。
          <br />
          しかし、勤怠管理システムを導入しようにも、中小企業に最適なシステムはどのようなものなのか分からないという人も多いでしょう。そこで、この記事では、
          <span>中小企業向けの勤怠管理システムの選び方</span>
          と、おすすめのシステムについて詳しく解説します。
        </p>

        <div id="toc1">
          <h1>
            中小企業でも勤怠管理システムの導入をおすすめする理由やメリットとは
          </h1>
        </div>
        <p>
          従業員の人数が少なく、Excelによる手動管理で間に合っている。そのため、勤怠管理システムの導入はまだしなくても問題ないのではないか？と考えている企業は多いでしょう。
          <br />
          しかし、人数の少ない中小企業であっても勤怠管理システムの導入はおすすめです。
          <br />
          では、その理由とは何か解説します。
        </p>
        <a href={routeParam} className="column-detail-link">
          参照記事:Excel(エクセル)でできる勤怠管理の限界とシステム導入で得られるメリットとは？
        </a>

        <div id="toc1-1">
          <h2>勤怠管理の効率化につながる</h2>
        </div>
        <p>
          勤怠管理を手動で行うと、従業員の出退勤の記録や残業時間の集計に時間がかかってしまいます。しかし、勤怠管理システムなら、これらの作業が自動化されるため、正確かつ迅速に処理可能です。
          <br />
          これにより、従業員や管理者は本来の業務に集中できるようになり、
          <span>生産性の向上につながります</span>
          。また勤怠データの透明性も高まり、従業員の働き方を適切に把握することが可能になるでしょう
        </p>

        <div id="toc1-2">
          <h2>法改正に対応できる</h2>
        </div>
        <p>
          勤怠管理システムを導入することで、法律の改正に迅速に対応できるというメリットがあります。法改正に合わせて都度、法律の内容を把握し、勤怠管理の方法を変更することは手間がかかります。
          <br />
          しかし、勤怠管理システムを利用すれば、システムが法改正に合わせて自動的にアップデートしてくれます。これにより、法改正に伴う手間や
          <span>法規制違反のリスクを軽減</span>できます。
        </p>
        <div id="toc1-3">
          <h2>人事戦略に活用できる</h2>
        </div>
        <p>
          勤怠管理システムは人事戦略にも役立ちます。
          <br />
          従業員の勤務データを正確に収集し分析することで、過剰労働や人材不足を把握し、適切な人員配置をするなど、労働環境の改善が可能です。それによって、従業員の労働環境への満足度が向上し、結果として企業の生産性の向上にもつながります。
          <br />
          <br />
          このように、勤怠管理システムを導入することでさまざまなメリットがあるため、中小企業でも導入はおすすめです。
        </p>
        <a href={routeParam2} className="column-detail-link">
          参照記事：勤怠管理システムを導入していない会社で発生するリスクとは？
        </a>

        {/** ここに9パターンの広告設定する */}
        {props.no === 0 && <Introduction004 />}
        {props.no === 1 && <Introduction005 />}
        {props.no === 2 && <Introduction006 />}
        {props.no === 3 && <Introduction007 />}
        {props.no === 4 && <Introduction008 />}
        {props.no === 5 && <Introduction009 />}
        {props.no === 6 && <Introduction010 />}
        {props.no === 7 && <Introduction011 />}
        {props.no === 8 && <Introduction012 />}

        <div id="toc2">
          <h1>中小企業向け勤怠管理システムの選び方</h1>
        </div>
        <img
          src="/images/column/details/c0089_g01.jpg"
          alt="キンクラコラム画像89"
        />
        <p>
          中小企業では、管理業務を担当する人数が限られていることがあります。したがって、担当者が少なくても簡単に管理できる勤怠管理システムを導入することが重要です。{' '}
          <br />
          ここでは、自社に適した勤怠管理システムを選ぶ際のポイントをいくつかご紹介します。
        </p>
        <div id="toc2-1">
          <h2>少人数での費用プランがある勤怠管理システムを選定する</h2>
        </div>
        <p>
          多くの中小企業では、従業員数が限られており大規模企業向けの高価なシステムは必要ありません。そのため、少人数でも費用対効果が高く、基本的な機能を網羅したプランが用意されたシステムを選定することが重要です。
          <br />
          <span>従業員数に応じた無料プラン</span>
          を提供しているシステムもあるため、そのようなプランがあるシステムを選定するのも良いでしょう。
        </p>
        <a href={routeParam3} className="column-detail-link">
          ⇒無料トライアルで「全機能」が使える、勤怠管理システム「キンクラ」とは
        </a>

        <div id="toc2-2">
          <h2>臨機応変な打刻方法が選択できる勤怠管理システムを選定する</h2>
        </div>
        <p>
          近年は働き方改革に伴い、様々な働き方を採用している中小企業も多いでしょう。
          <br />
          特に最近は、一部リモートワークやフレックスタイム制といった働き方を取り入れている中小企業も多いかと思います。そのため、臨機応変に打刻できる勤怠管理システムを選定することが重要です。
          <br />
          例えば、PCによる打刻だけではなく、スマートフォンから打刻できるというように、
          <span>柔軟に打刻できるシステムを選定</span>
          するのが良いでしょう。これにより、外勤が多い従業員やリモートワークを採用している企業でも、効率的に勤怠管理ができます。
        </p>
        <div id="toc2-3">
          <h2>
            使いやすく、サポート体制が整っている勤怠管理システムを選定する
          </h2>
        </div>
        <p>
          中小企業では、大企業のように情報システム部門があるとは限りません。そのため、使いやすく、導入後のサポートが充実しているシステムが必要になります。
          使いづらく、サポートがあまり充実していない勤怠管理システムを選定してしまった場合、トラブルが起きた際には、うまく対応が出来ないといったリスクがあるでしょう。
          <br />
          そのため、
          <span>サポート体制が整っている勤怠管理システムを選定する</span>
          ことが重要になります。
        </p>
        <div id="toc2-4">
          <h2>クラウド型の勤怠管理システムを選定する</h2>
        </div>
        <p>
          クラウド型の勤怠管理システムを選定することをおすすめします。クラウド型の勤怠管理システムは初期投資が少なく、どこからでもアクセスが可能です。
          <br />
          また、セキュリティと機能が最新になるように自動的にアップデートされます。これにより、中小企業でも最新の技術を利用して効率的な勤怠管理が可能です。
          <br />
          <br />
          以上のポイントを押さえることで、中小企業に適した勤怠管理システムを選定することが出来ます。
        </p>
        <a href={routeParam4} className="column-detail-link">
          参照記事：勤怠管理は経費精算付きのクラウドがおすすめ！ その理由とは...
        </a>

        <div id="toc3">
          <h1>中小企業向け勤怠管理システムには「キンクラ」がおすすめ</h1>
        </div>
        <p>
          ここまで解説したように、中小企業が勤怠管理システムを導入する場合、充実したサポート体制や、
          <span>費用対効果の高い勤怠管理システム</span>
          を導入することが重要になります。
          <br />
          また、フレックスタイム制やリモートワークなど多様な働き方を採用している企業などは、臨機応変に打刻できる勤怠管理システムを選定するのが容易でしょう。
          <br />
          これらのポイントを押さえて、中小企業に適した勤怠管理システムを選定することが可能になります。
          <br />
          <br />
          もし、勤怠管理システムの導入を検討しているならば、「キンクラ」は、中小企業向けの勤怠管理システムとしておすすめです。
          <br />
          「キンクラ」では、全機能が無料で使える「無料トライアル」を実施していますので、勤怠管理システム導入を検討しているならば「キンクラ」をお試しください。
        </p>
      </section>
    </Wrapper>
  )
}

export default ColumnDetailInner0089
